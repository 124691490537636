// extracted by mini-css-extract-plugin
export var fixed = "Header-module--fixed--b7669";
export var fixedIndicator = "Header-module--fixedIndicator--f6397";
export var header = "Header-module--header--e83f0";
export var headerButtons = "Header-module--headerButtons--9a875";
export var heading = "Header-module--heading--986f8";
export var headingLink = "Header-module--headingLink--c7875";
export var headingWrapper = "Header-module--headingWrapper--c8279";
export var icon = "Header-module--icon--657d7";
export var iconButton = "Header-module--iconButton--48d61";
export var link = "Header-module--link--c49c3";
export var progressBar = "Header-module--progressBar--198ca";
export var shrink = "Header-module--shrink--44429";
export var wrapper = "Header-module--wrapper--df00d";