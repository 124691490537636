module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@9.19.0_jiti@2.4.2___7yjkuw5igyapkuu3gnkytx3wwq/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-8JQSR962RQ"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@7.14.0_gatsby-plugin-sharp@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_esli_3k3dxxy7nssmb7vibj6w5elm3i/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":780,"linkImagesToOriginal":false,"wrapperStyle":"border-radius: 5px; overflow: hidden;","showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-autolink-headers@6.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@9.19.0_jiti@2._fxuxhptyj3grrjoewaw2dq436y/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":false,"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@9.19.0_jiti@2.4.2___es_3taaxp7ne5kimzgwyqugrqvmaq/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"/home/runner/work/jiunbae.github.io/jiunbae.github.io/static/profile.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"69f695984baa083ac8c27124c60f6b3b"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_eslint@9.19.0_jiti@2.4.2___eslint-import-resolver-typescrip_lokick4djdo27w5toefmqbiwwa/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
